import { mapActions, mapGetters } from 'vuex'

const Modal = () => import(/* webpackChunkName = "c-modal" */'@/components/Modal')
const emptyField = (name) => `${name} tidak boleh kosong`

export default {
  name: 'AddBannerForm',
  Components: {
    Modal
  },
  data () {
    return {
      title: '',
      imageUrl: '',
      redirectUrl: '',
      youtubeVideoId: '',
      type: 'DEFAULT',
      errors: []
    }
  },
  computed: {
    ...mapGetters('user', ['token']),
    requestData () {
      return {
        imageUrl: this.imageUrl,
        redirectUrl: this.redirectUrl,
        youtubeVideoId: this.youtubeVideoId,
        title: this.title,
        type: this.type,
        token: this.token
      }
    },
    errorsText () {
      return this.errors.join(', ')
    }
  },
  methods: {
    ...mapActions('banner', ['createBannersAction', 'getBannersAction']),
    close () {
      this.$emit('close')
    },
    handleSubmit () {
      this.validateForm()
      if (this.errors.length === 0) {
        this.createBannersAction({
          data: this.requestData,
          success: this.handleSuccessSubmit
        })
      }
    },
    handleSuccessSubmit () {
      this.$swal({
        icon: 'success',
        title: 'Success Submit',
        showConfirmButton: false,
        timer: 1500
      })
      this.getBannersAction({
        success: () => {}
      })
      this.close()
    },
    validateForm () {
      this.errors = []
      if (this.title === '') this.errors.push(emptyField('Judul'))
      if (this.imageUrl === '') this.errors.push(emptyField('Alamat Gambar'))
      if (this.type === 'DEFAULT' && this.redirectUrl === '') this.errors.push(emptyField('Alamat Url'))
      if (this.type === 'YOUTUBE' && this.youtubeVideoId === '') this.errors.push(emptyField('Youtube video Id'))
    }
  }
}
